import { defineStore } from 'pinia'

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    isActive: false,
    emailVerification: '',
  }),

  persist: {
    storage: persistedState.sessionStorage,
    pick: ['emailVerification', 'isActive'],
  },

  actions: {

  },
})
