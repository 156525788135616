import validate from "/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import acl_45global from "/src/middleware/acl.global.ts";
import manifest_45route_45rule from "/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  acl_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/src/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}