import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notificationStore', {
  state: () => ({
    list: [],
    entry: [],
    chat: [],
    owner: [],
    campaignId: '',
  }),

  actions: {
    async fetches(isAdmin?: boolean) {
      const { data: entryNoti } = await useApi<any>('/entries/unread')
      const { data: chatNoti } = await useApi<any>('/client_store_chats/unread')

      if (isAdmin) {
        const { data: ownerNoti } = await useApi<any>('/owner_chats/unread')

        this.owner = ownerNoti.value
      }

      this.entry = entryNoti.value
      this.chat = chatNoti.value
    },

    async fetchesOwner() {
      const { data: ownerNoti } = await useApi<any>('/owner_chats/unread')

      this.owner = ownerNoti.value
    },

    async fetchChat() {
      this.chat = await $api<any>('/client_store_chats/unread')
    },

    async fetchEntry() {
      this.entry = await $api<any>('/entries/unread')
    },
  },
})
