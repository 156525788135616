<script setup lang="ts">
import { useSnackbarStore } from '@/stores/snackbar'

const snackbarStore = useSnackbarStore()
</script>

<template>
  <VSnackbar
    v-model="snackbarStore.isActive"
    multi-line
    timeout="5000"
    :color="snackbarStore.color"
    location="bottom end"
    variant="flat"
    vertical
    @on-vnode-unmounted="snackbarStore.unsetSnackbar()"
  >
    <div
      v-if="snackbarStore.title"
      class="text-title pb-2"
    >{{ snackbarStore.title }}</div>

    <p class="content-overflow-wrap">
      {{ snackbarStore.text }}
    </p>
  </VSnackbar>
</template>
