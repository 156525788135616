export default {
  blank: () => import("/src/layouts/blank.vue").then(m => m.default || m),
  "components-default-layout-with-horizontal-nav": () => import("/src/layouts/components/DefaultLayoutWithHorizontalNav.vue").then(m => m.default || m),
  "components-default-layout-with-horizontal-nav-landing": () => import("/src/layouts/components/DefaultLayoutWithHorizontalNavLanding.vue").then(m => m.default || m),
  "components-default-layout-with-vertical-nav": () => import("/src/layouts/components/DefaultLayoutWithVerticalNav.vue").then(m => m.default || m),
  "components-default-layout-with-vertical-nav-landing": () => import("/src/layouts/components/DefaultLayoutWithVerticalNavLanding.vue").then(m => m.default || m),
  "components-footer": () => import("/src/layouts/components/Footer.vue").then(m => m.default || m),
  "components-nav-bar-notifications": () => import("/src/layouts/components/NavBarNotifications.vue").then(m => m.default || m),
  "components-nav-search-bar": () => import("/src/layouts/components/NavSearchBar.vue").then(m => m.default || m),
  "components-navbar-shortcuts": () => import("/src/layouts/components/NavbarShortcuts.vue").then(m => m.default || m),
  "components-navbar-theme-switcher": () => import("/src/layouts/components/NavbarThemeSwitcher.vue").then(m => m.default || m),
  "components-reload": () => import("/src/layouts/components/Reload.vue").then(m => m.default || m),
  "components-user-profile": () => import("/src/layouts/components/UserProfile.vue").then(m => m.default || m),
  default: () => import("/src/layouts/default.vue").then(m => m.default || m),
  landing: () => import("/src/layouts/landing.vue").then(m => m.default || m),
  withlogo: () => import("/src/layouts/withlogo.vue").then(m => m.default || m)
}