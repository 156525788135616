import type { MaybeRefOrGetter } from 'vue'
import { stringify } from 'qs'

interface Options {
  query: MaybeRefOrGetter<Record<string, any>>
}

export const createUrl = (url: MaybeRefOrGetter<string>, options?: Options) => computed(() => {
  if (!options?.query)
    return toValue(url)

  const _url = toValue(url)
  const _query = toValue(options?.query)

  const queryObj = defuReplaceArray(_query)

  return `${_url}${queryObj ? `?${stringify(queryObj)}` : ''}`
})
