import {
  createDirectus,
  createItem,
  readItem,
  readItems,
  readSingleton,
  rest,
  staticToken,
  updateItem,
  withToken,
} from '@directus/sdk'
import type { Schema } from '~/types/schema'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const directusUrl = config.public.directusBaseUrl as string
  const directusToken = config.public.directusToken as string

  const directus = createDirectus<Schema>(directusUrl)
    .with(rest())
    .with(staticToken(directusToken))

  return {
    provide: { directus, readItem, readItems, readSingleton, createItem, updateItem, withToken },
  }
})
