import { Loader } from '@googlemaps/js-api-loader'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      loader: new Loader({
        apiKey: useRuntimeConfig().public.googleMapsApiKey,
        version: 'weekly',
        libraries: ['places'],
      }),
    },
  }
})
