import { getApps, initializeApp } from 'firebase/app'
import { type Messaging, getMessaging, onMessage } from 'firebase/messaging'
import { useSnackbarStore } from '@/stores/snackbar'
import { useNotificationStore } from '@/stores/notification'

declare module '#app' {
  interface NuxtApp {
    $messaging: Messaging
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $messaging: Messaging
  }
}

export default defineNuxtPlugin(() => {
  const app = getApps()[0] ?? initializeApp({
    apiKey: 'AIzaSyCyEEKp5OTcRZ3pzHHaeV66-xvHQ6qmgyw',
    authDomain: 'bashotoru-notifications.firebaseapp.com',
    projectId: 'bashotoru-notifications',
    storageBucket: 'bashotoru-notifications.appspot.com',
    messagingSenderId: '1060206254639',
    appId: '1:1060206254639:web:f7b94aebcb3e3286f944da',
    measurementId: 'G-83YC05CPMY',
  })

  const messaging = getMessaging(app)

  const { isApple, isIos } = useDevice()

  // This runs whenever a message is received:
  //   - When the page is open
  //   - When the user clicked the bg notification
  const snackbarStore = useSnackbarStore()
  const notificationStore = useNotificationStore()

  if (!isIos) {
    onMessage(messaging, (payload: any) => {
      const notificationTitle = payload.data.title
      const body = payload.data.body

      // if (isApple && isIos) {
      //   const notificationOptions = {
      //     body,
      //     data: payload.data ? { url: payload.data.url, entryId: payload.data.entryId, chatId: payload.data.chatId } : null,
      //     icon: '/icon-512x512.png',
      //   }

      //   navigator.serviceWorker.getRegistration().then((reg: any) => {
      //     reg.showNotification(notificationTitle, notificationOptions)
      //   })
      // }
      // else {
      //   snackbarStore.setNotification(notificationTitle, body)
      // }

      // const notificationOptions = {
      //   body,
      //   data: payload.data ? { url: payload.data.url, entryId: payload.data.entry_id, chatId: payload.data.chat_id } : null,
      //   icon: '/icon-512x512.png',
      // }

      // navigator.serviceWorker.getRegistration().then((reg: any) => {
      //   reg.showNotification(notificationTitle, notificationOptions)
      // })

      snackbarStore.setNotification(notificationTitle, body)

      // update notification
      notificationStore.fetches()
    })
  }

  return {
    provide: {
      messaging,
    },
  }
})
