<script setup lang="ts">
import { useTheme } from 'vuetify'
import ScrollToTop from '@core/components/ScrollToTop.vue'
import initCore from '@core/initCore'
import { initConfigStore, useConfigStore } from '@core/stores/config'
import { hexToRgb } from '@layouts/utils'

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
initCore()
initConfigStore()

const configStore = useConfigStore()
const { $pwa } = useNuxtApp()
</script>

<template>
  <VLocaleProvider :rtl="configStore.isAppRTL">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <NuxtLayout>
        <ClientOnly>
          <VitePwaManifest />
        </ClientOnly>
        <NuxtPage />
        <ActivityDialog />
      </NuxtLayout>
      <ScrollToTop />
      <AppSnackbar />
      <!-- <Survey /> -->
      <PwaInstall v-if="$pwa?.showInstallPrompt" />
    </VApp>
  </VLocaleProvider>
</template>
