<script lang="ts" setup>
const { $pwa } = useNuxtApp()
</script>

<template>
  <IconBtn
    id="divInstallApp"
    class="install-button d-print-none"
    color="whilte"
    size="50"
    @click="$pwa?.install()"
  >
    <VIcon icon="tabler-apps" />
    <VTooltip
      activator="parent"
      location="top"
    >
      インストール場所とる.com
    </VTooltip>
  </IconBtn>
</template>

<style lang="scss" scoped>
.install-button,
.button-inner {
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 6px;
  margin: 0;
  animation: anime 12s linear infinite;
  appearance: none;
  background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5);
  background-size: 600%;
  color: rgba(255, 255, 255, 90%);
  cursor: pointer;
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.43px;
  line-height: 1.2;
  min-inline-size: 50px;
  outline: 0;
  padding-block: 0.625rem;
  padding-inline: 1.25rem;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
}

.v-img__img,
.install-button{
  position: fixed;
  z-index: 999;
  inset-block-end: 5%;
  inset-inline-end: 70px;

  &:hover {
    color: white;
    text-decoration: none;
  }

  .button-inner {
    position: absolute;
    z-index: -1;
    filter: blur(12px);
    inset: 0;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  &:not(:hover) .button-inner {
    opacity: 0.8;
  }
}

@keyframes anime {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
</style>
