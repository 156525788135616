<script lang="ts" setup>
import { useDayjs } from '#dayjs'
import { useAuthStore } from '@/stores/auth'
import { themeConfig } from '@themeConfig'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import terms from '@/assets/pdf/場所とるPR利用規約.pdf'

const dayjs = useDayjs()
const authStore = useAuthStore()
const userStore = useUserStore()
const userData = useCookie<any>('userData')

const updateUser = () => {
  userStore.update({ id: userData.value.id, email_verified_at: dayjs().format('YYYY-MM-DD') }).then(() => {
    authStore.isActive = false
  })
    .catch(error => {
      console.error(error)
    })
}
</script>

<template>
  <VDialog
    v-model="authStore.isActive"
    fullscreen
    :scrim="false"
    scrollable
    content-class="scrollable-dialog"
    transition="dialog-bottom-transition"
  >
    <!-- Dialog Content -->
    <VCard>
      <template #prepend>
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <VNodeRenderer :nodes="themeConfig.app.logo" />
          </div>
          <VDivider />
        </div>
      </template>
      <template #append>
        <VBtn @click="updateUser">
          同意する
        </VBtn>
      </template>
      <VCardItem class="justify-center">
        <VCardTitle class="font-weight-bold text-h3 py-1">
          <h4 class="text-truncate font-weight-bold">
            利用規約
          </h4>
        </VCardTitle>
      </VCardItem>

      <VCardText>
        <VRow justify="center">
          <VCol
            cols="12"
            sm="8"
            lg="6"
          >
            <iframe :src="`${terms}#page=1`" />
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style>
iframe{
        display: block; 
        height: 70vh;
        width: 100%;
        border: none;
        background: lightyellow;
    }
</style>
