import type { RouteRecordRaw } from 'vue-router'
import type { RouterConfig } from '@nuxt/schema'

// 👉 Redirects
const redirects: RouteRecordRaw[] = [
  // ℹ️ We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    name: 'index',

    // meta: {
    //   middleware: to => {
    //     const { data: sessionData } = useAuth()

    //     const userRole = sessionData.value?.user.role

    //     if (userRole) {
    //       switch (userRole) {
    //         case 'client':
    //           return { name: 'clients-dashboard' }
    //           break
    //         case 'admin':
    //           return { name: 'admins-dashboard' }
    //           break
    //         case 'owner':
    //           return { name: 'owners-dashboard' }
    //           break
    //         default:
    //           return { name: 'login', query: to.query }
    //       }
    //     }

    //     return { name: 'login', query: to.query }
    //   },
    // },
    component: h('div'),
  },
  {
    path: '/pages/user-profile',
    name: 'pages-user-profile',
    redirect: () => ({ name: 'pages-user-profile-tab', params: { tab: 'profile' } }),
    meta: {
      action: 'manage',
      subject: 'common',
    },
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    redirect: () => ({ name: 'pages-account-settings-tab', params: { tab: 'account' } }),
    meta: {
      action: 'manage',
      subject: 'common',
    },
  },
]

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  routes: scannedRoutes => [
    ...redirects,
    ...scannedRoutes,
  ],
}
