import { default as _91_46_46_46permalink_93mgoeasT2ePMeta } from "/src/pages/[...permalink].vue?macro=true";
import { default as bookmark3YhS3gvApDMeta } from "/src/pages/admins/bookmark.vue?macro=true";
import { default as clientPukoAiKi5eMeta } from "/src/pages/admins/chat/client.vue?macro=true";
import { default as ownerOGAOmitUS2Meta } from "/src/pages/admins/chat/owner.vue?macro=true";
import { default as dashboardBRHiz1u3W3Meta } from "/src/pages/admins/dashboard.vue?macro=true";
import { default as documentx0Kj0cWRNEMeta } from "/src/pages/admins/document.vue?macro=true";
import { default as _91id_93IWeam1kQEPMeta } from "/src/pages/admins/entry/[id].vue?macro=true";
import { default as indexKa6koOVrS5Meta } from "/src/pages/admins/entry/index.vue?macro=true";
import { default as _91id_93YWhaqArcgiMeta } from "/src/pages/admins/facility/[id].vue?macro=true";
import { default as _91id_933F6NoWqwBmMeta } from "/src/pages/admins/form/[id].vue?macro=true";
import { default as indext7Gsbs33m6Meta } from "/src/pages/admins/history/index.vue?macro=true";
import { default as loginlnIAi6Hep4Meta } from "/src/pages/admins/history/login.vue?macro=true";
import { default as newszbKJOWH8QPMeta } from "/src/pages/admins/news.vue?macro=true";
import { default as pickuphmPlXkbfAPMeta } from "/src/pages/admins/pickup.vue?macro=true";
import { default as reportB7kvO52s2PMeta } from "/src/pages/admins/report.vue?macro=true";
import { default as searchJDUDrmzdNpMeta } from "/src/pages/admins/search.vue?macro=true";
import { default as admin0a4h5yaWY0Meta } from "/src/pages/admins/setting/admin.vue?macro=true";
import { default as apiIgQcASEXouMeta } from "/src/pages/admins/setting/api.vue?macro=true";
import { default as client70N4ShDUnYMeta } from "/src/pages/admins/setting/client.vue?macro=true";
import { default as indexCBN0QC71qnMeta } from "/src/pages/admins/setting/commission/index.vue?macro=true";
import { default as typeJMbxF48KboMeta } from "/src/pages/admins/setting/commission/type.vue?macro=true";
import { default as indexZmszcnsrXKMeta } from "/src/pages/admins/setting/facility/index.vue?macro=true";
import { default as rankPZuzsAuHwbMeta } from "/src/pages/admins/setting/facility/rank.vue?macro=true";
import { default as faqHy4lQwUPBQMeta } from "/src/pages/admins/setting/faq.vue?macro=true";
import { default as help8aIrscnZ1FMeta } from "/src/pages/admins/setting/help.vue?macro=true";
import { default as itemWo5LQqNg0rMeta } from "/src/pages/admins/setting/item.vue?macro=true";
import { default as owner8kMyWRKDqOMeta } from "/src/pages/admins/setting/owner.vue?macro=true";
import { default as comment3FtwVw4UAMMeta } from "/src/pages/admins/setting/place/comment.vue?macro=true";
import { default as disablehQ3e7byWljMeta } from "/src/pages/admins/setting/place/disable.vue?macro=true";
import { default as index3ttkmkxqebMeta } from "/src/pages/admins/setting/place/index.vue?macro=true";
import { default as storepnVLZ1kUT1Meta } from "/src/pages/admins/setting/store.vue?macro=true";
import { default as typeMFzJVOQpm7Meta } from "/src/pages/admins/setting/type.vue?macro=true";
import { default as userU8rK9UaTuPMeta } from "/src/pages/admins/setting/user.vue?macro=true";
import { default as bookmarkPyFKWGbHiuMeta } from "/src/pages/clients/bookmark.vue?macro=true";
import { default as calendarhX3SjdkZeyMeta } from "/src/pages/clients/calendar.vue?macro=true";
import { default as chat69mCtFxI0CMeta } from "/src/pages/clients/chat.vue?macro=true";
import { default as dashboardt3bacwKS5OMeta } from "/src/pages/clients/dashboard.vue?macro=true";
import { default as entryRlKOnGTatKMeta } from "/src/pages/clients/entry.vue?macro=true";
import { default as _91id_93eKqeYrDmK5Meta } from "/src/pages/clients/facility/[id].vue?macro=true";
import { default as _91id_93NDIUShEzBdMeta } from "/src/pages/clients/form/[id].vue?macro=true";
import { default as newsTPmIt7Je5TMeta } from "/src/pages/clients/news.vue?macro=true";
import { default as pickup1mymelIzWwMeta } from "/src/pages/clients/pickup.vue?macro=true";
import { default as requestExQer3fonyMeta } from "/src/pages/clients/request.vue?macro=true";
import { default as searchnJfEkANpgvMeta } from "/src/pages/clients/search.vue?macro=true";
import { default as contactjt1IjhjjXuMeta } from "/src/pages/contact.vue?macro=true";
import { default as _91id_93RSPsdBAuegMeta } from "/src/pages/facility/[id].vue?macro=true";
import { default as newnu4fVe7aCQMeta } from "/src/pages/facility/new.vue?macro=true";
import { default as pickup4zcGKG7AkNMeta } from "/src/pages/facility/pickup.vue?macro=true";
import { default as rankGaRX3pKmP6Meta } from "/src/pages/facility/rank.vue?macro=true";
import { default as forgot_45passwordhkQDFGWAYaMeta } from "/src/pages/forgot-password.vue?macro=true";
import { default as _91id_932BpQp3ektUMeta } from "/src/pages/help/articles/[id].vue?macro=true";
import { default as _91id_93vorN6bxGqPMeta } from "/src/pages/help/collections/[id].vue?macro=true";
import { default as index2yHnvb2Km1Meta } from "/src/pages/help/index.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as loginTLBFe6DICLMeta } from "/src/pages/login.vue?macro=true";
import { default as memberl7IublsEQmMeta } from "/src/pages/member.vue?macro=true";
import { default as not_45authorizedFi7G6I00NxMeta } from "/src/pages/not-authorized.vue?macro=true";
import { default as callbackYfUopZFkVZMeta } from "/src/pages/oauth/facebook/callback.vue?macro=true";
import { default as callback6VxB2h7dixMeta } from "/src/pages/oauth/google/callback.vue?macro=true";
import { default as callbackpEbZOELfR1Meta } from "/src/pages/oauth/instagrambasic/callback.vue?macro=true";
import { default as callbackNoPteqFKraMeta } from "/src/pages/oauth/twitter/callback.vue?macro=true";
import { default as calendarFZM1aQ4TutMeta } from "/src/pages/owners/calendar.vue?macro=true";
import { default as chatAs5puJNMWkMeta } from "/src/pages/owners/chat.vue?macro=true";
import { default as dashboardlkzeD8FqQhMeta } from "/src/pages/owners/dashboard.vue?macro=true";
import { default as entryUjlmjcqKufMeta } from "/src/pages/owners/entry.vue?macro=true";
import { default as indexQEz8wDx0cVMeta } from "/src/pages/owners/facility/index.vue?macro=true";
import { default as newsKeGLLEmUmMMeta } from "/src/pages/owners/news.vue?macro=true";
import { default as pickupOtTUFGJxo1Meta } from "/src/pages/owners/pickup.vue?macro=true";
import { default as salesTQRZkqHgfWMeta } from "/src/pages/owners/sales.vue?macro=true";
import { default as schedulergspfGQ0xDwMeta } from "/src/pages/owners/scheduler.vue?macro=true";
import { default as _91tab_93a8L4j3Uzz1Meta } from "/src/pages/pages/account-settings/[tab].vue?macro=true";
import { default as faqhUdqgC6OBuMeta } from "/src/pages/pages/faq.vue?macro=true";
import { default as _91article_93BIhv5Q7gqYMeta } from "/src/pages/pages/help-center/[category]/[subcategory]/[article].vue?macro=true";
import { default as indexbrDIgPtEH5Meta } from "/src/pages/pages/help-center/[category]/[subcategory]/index.vue?macro=true";
import { default as indexSkmVw8vtlsMeta } from "/src/pages/pages/help-center/index.vue?macro=true";
import { default as coming_45soonUaHZKkFWv7Meta } from "/src/pages/pages/misc/coming-soon.vue?macro=true";
import { default as under_45maintenanceQzWFZnfw1HMeta } from "/src/pages/pages/misc/under-maintenance.vue?macro=true";
import { default as _91slug_93qw2vZTRxA1Meta } from "/src/pages/post/[slug].vue?macro=true";
import { default as _91category_936RbuKwksezMeta } from "/src/pages/post/categories/[category].vue?macro=true";
import { default as indexP5DXecaF7bMeta } from "/src/pages/post/index.vue?macro=true";
import { default as register9mc1FDQpDSMeta } from "/src/pages/register.vue?macro=true";
import { default as reset_45passwordwGIRYTe8GpMeta } from "/src/pages/reset-password.vue?macro=true";
import { default as searchtAmKYCO6YgMeta } from "/src/pages/search.vue?macro=true";
import { default as verificationD2Q3SEMGAfMeta } from "/src/pages/verification.vue?macro=true";
export default [
  {
    name: _91_46_46_46permalink_93mgoeasT2ePMeta?.name ?? "permalink",
    path: _91_46_46_46permalink_93mgoeasT2ePMeta?.path ?? "/:permalink(.*)*",
    meta: _91_46_46_46permalink_93mgoeasT2ePMeta || {},
    alias: _91_46_46_46permalink_93mgoeasT2ePMeta?.alias || [],
    redirect: _91_46_46_46permalink_93mgoeasT2ePMeta?.redirect,
    component: () => import("/src/pages/[...permalink].vue").then(m => m.default || m)
  },
  {
    name: bookmark3YhS3gvApDMeta?.name ?? "admins-bookmark",
    path: bookmark3YhS3gvApDMeta?.path ?? "/admins/bookmark",
    meta: bookmark3YhS3gvApDMeta || {},
    alias: bookmark3YhS3gvApDMeta?.alias || [],
    redirect: bookmark3YhS3gvApDMeta?.redirect,
    component: () => import("/src/pages/admins/bookmark.vue").then(m => m.default || m)
  },
  {
    name: clientPukoAiKi5eMeta?.name ?? "admins-chat-client",
    path: clientPukoAiKi5eMeta?.path ?? "/admins/chat/client",
    meta: clientPukoAiKi5eMeta || {},
    alias: clientPukoAiKi5eMeta?.alias || [],
    redirect: clientPukoAiKi5eMeta?.redirect,
    component: () => import("/src/pages/admins/chat/client.vue").then(m => m.default || m)
  },
  {
    name: ownerOGAOmitUS2Meta?.name ?? "admins-chat-owner",
    path: ownerOGAOmitUS2Meta?.path ?? "/admins/chat/owner",
    meta: ownerOGAOmitUS2Meta || {},
    alias: ownerOGAOmitUS2Meta?.alias || [],
    redirect: ownerOGAOmitUS2Meta?.redirect,
    component: () => import("/src/pages/admins/chat/owner.vue").then(m => m.default || m)
  },
  {
    name: dashboardBRHiz1u3W3Meta?.name ?? "admins-dashboard",
    path: dashboardBRHiz1u3W3Meta?.path ?? "/admins/dashboard",
    meta: dashboardBRHiz1u3W3Meta || {},
    alias: dashboardBRHiz1u3W3Meta?.alias || [],
    redirect: dashboardBRHiz1u3W3Meta?.redirect,
    component: () => import("/src/pages/admins/dashboard.vue").then(m => m.default || m)
  },
  {
    name: documentx0Kj0cWRNEMeta?.name ?? "admins-document",
    path: documentx0Kj0cWRNEMeta?.path ?? "/admins/document",
    meta: documentx0Kj0cWRNEMeta || {},
    alias: documentx0Kj0cWRNEMeta?.alias || [],
    redirect: documentx0Kj0cWRNEMeta?.redirect,
    component: () => import("/src/pages/admins/document.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IWeam1kQEPMeta?.name ?? "admins-entry-id",
    path: _91id_93IWeam1kQEPMeta?.path ?? "/admins/entry/:id()",
    meta: _91id_93IWeam1kQEPMeta || {},
    alias: _91id_93IWeam1kQEPMeta?.alias || [],
    redirect: _91id_93IWeam1kQEPMeta?.redirect,
    component: () => import("/src/pages/admins/entry/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKa6koOVrS5Meta?.name ?? "admins-entry",
    path: indexKa6koOVrS5Meta?.path ?? "/admins/entry",
    meta: indexKa6koOVrS5Meta || {},
    alias: indexKa6koOVrS5Meta?.alias || [],
    redirect: indexKa6koOVrS5Meta?.redirect,
    component: () => import("/src/pages/admins/entry/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YWhaqArcgiMeta?.name ?? "admins-facility-id",
    path: _91id_93YWhaqArcgiMeta?.path ?? "/admins/facility/:id()",
    meta: _91id_93YWhaqArcgiMeta || {},
    alias: _91id_93YWhaqArcgiMeta?.alias || [],
    redirect: _91id_93YWhaqArcgiMeta?.redirect,
    component: () => import("/src/pages/admins/facility/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_933F6NoWqwBmMeta?.name ?? "admins-form-id",
    path: _91id_933F6NoWqwBmMeta?.path ?? "/admins/form/:id()",
    meta: _91id_933F6NoWqwBmMeta || {},
    alias: _91id_933F6NoWqwBmMeta?.alias || [],
    redirect: _91id_933F6NoWqwBmMeta?.redirect,
    component: () => import("/src/pages/admins/form/[id].vue").then(m => m.default || m)
  },
  {
    name: indext7Gsbs33m6Meta?.name ?? "admins-history",
    path: indext7Gsbs33m6Meta?.path ?? "/admins/history",
    meta: indext7Gsbs33m6Meta || {},
    alias: indext7Gsbs33m6Meta?.alias || [],
    redirect: indext7Gsbs33m6Meta?.redirect,
    component: () => import("/src/pages/admins/history/index.vue").then(m => m.default || m)
  },
  {
    name: loginlnIAi6Hep4Meta?.name ?? "admins-history-login",
    path: loginlnIAi6Hep4Meta?.path ?? "/admins/history/login",
    meta: loginlnIAi6Hep4Meta || {},
    alias: loginlnIAi6Hep4Meta?.alias || [],
    redirect: loginlnIAi6Hep4Meta?.redirect,
    component: () => import("/src/pages/admins/history/login.vue").then(m => m.default || m)
  },
  {
    name: newszbKJOWH8QPMeta?.name ?? "admins-news",
    path: newszbKJOWH8QPMeta?.path ?? "/admins/news",
    meta: newszbKJOWH8QPMeta || {},
    alias: newszbKJOWH8QPMeta?.alias || [],
    redirect: newszbKJOWH8QPMeta?.redirect,
    component: () => import("/src/pages/admins/news.vue").then(m => m.default || m)
  },
  {
    name: pickuphmPlXkbfAPMeta?.name ?? "admins-pickup",
    path: pickuphmPlXkbfAPMeta?.path ?? "/admins/pickup",
    meta: pickuphmPlXkbfAPMeta || {},
    alias: pickuphmPlXkbfAPMeta?.alias || [],
    redirect: pickuphmPlXkbfAPMeta?.redirect,
    component: () => import("/src/pages/admins/pickup.vue").then(m => m.default || m)
  },
  {
    name: reportB7kvO52s2PMeta?.name ?? "admins-report",
    path: reportB7kvO52s2PMeta?.path ?? "/admins/report",
    meta: reportB7kvO52s2PMeta || {},
    alias: reportB7kvO52s2PMeta?.alias || [],
    redirect: reportB7kvO52s2PMeta?.redirect,
    component: () => import("/src/pages/admins/report.vue").then(m => m.default || m)
  },
  {
    name: searchJDUDrmzdNpMeta?.name ?? "admins-search",
    path: searchJDUDrmzdNpMeta?.path ?? "/admins/search",
    meta: searchJDUDrmzdNpMeta || {},
    alias: searchJDUDrmzdNpMeta?.alias || [],
    redirect: searchJDUDrmzdNpMeta?.redirect,
    component: () => import("/src/pages/admins/search.vue").then(m => m.default || m)
  },
  {
    name: admin0a4h5yaWY0Meta?.name ?? "admins-setting-admin",
    path: admin0a4h5yaWY0Meta?.path ?? "/admins/setting/admin",
    meta: admin0a4h5yaWY0Meta || {},
    alias: admin0a4h5yaWY0Meta?.alias || [],
    redirect: admin0a4h5yaWY0Meta?.redirect,
    component: () => import("/src/pages/admins/setting/admin.vue").then(m => m.default || m)
  },
  {
    name: apiIgQcASEXouMeta?.name ?? "admins-setting-api",
    path: apiIgQcASEXouMeta?.path ?? "/admins/setting/api",
    meta: apiIgQcASEXouMeta || {},
    alias: apiIgQcASEXouMeta?.alias || [],
    redirect: apiIgQcASEXouMeta?.redirect,
    component: () => import("/src/pages/admins/setting/api.vue").then(m => m.default || m)
  },
  {
    name: client70N4ShDUnYMeta?.name ?? "admins-setting-client",
    path: client70N4ShDUnYMeta?.path ?? "/admins/setting/client",
    meta: client70N4ShDUnYMeta || {},
    alias: client70N4ShDUnYMeta?.alias || [],
    redirect: client70N4ShDUnYMeta?.redirect,
    component: () => import("/src/pages/admins/setting/client.vue").then(m => m.default || m)
  },
  {
    name: indexCBN0QC71qnMeta?.name ?? "admins-setting-commission",
    path: indexCBN0QC71qnMeta?.path ?? "/admins/setting/commission",
    meta: indexCBN0QC71qnMeta || {},
    alias: indexCBN0QC71qnMeta?.alias || [],
    redirect: indexCBN0QC71qnMeta?.redirect,
    component: () => import("/src/pages/admins/setting/commission/index.vue").then(m => m.default || m)
  },
  {
    name: typeJMbxF48KboMeta?.name ?? "admins-setting-commission-type",
    path: typeJMbxF48KboMeta?.path ?? "/admins/setting/commission/type",
    meta: typeJMbxF48KboMeta || {},
    alias: typeJMbxF48KboMeta?.alias || [],
    redirect: typeJMbxF48KboMeta?.redirect,
    component: () => import("/src/pages/admins/setting/commission/type.vue").then(m => m.default || m)
  },
  {
    name: indexZmszcnsrXKMeta?.name ?? "admins-setting-facility",
    path: indexZmszcnsrXKMeta?.path ?? "/admins/setting/facility",
    meta: indexZmszcnsrXKMeta || {},
    alias: indexZmszcnsrXKMeta?.alias || [],
    redirect: indexZmszcnsrXKMeta?.redirect,
    component: () => import("/src/pages/admins/setting/facility/index.vue").then(m => m.default || m)
  },
  {
    name: rankPZuzsAuHwbMeta?.name ?? "admins-setting-facility-rank",
    path: rankPZuzsAuHwbMeta?.path ?? "/admins/setting/facility/rank",
    meta: rankPZuzsAuHwbMeta || {},
    alias: rankPZuzsAuHwbMeta?.alias || [],
    redirect: rankPZuzsAuHwbMeta?.redirect,
    component: () => import("/src/pages/admins/setting/facility/rank.vue").then(m => m.default || m)
  },
  {
    name: faqHy4lQwUPBQMeta?.name ?? "admins-setting-faq",
    path: faqHy4lQwUPBQMeta?.path ?? "/admins/setting/faq",
    meta: faqHy4lQwUPBQMeta || {},
    alias: faqHy4lQwUPBQMeta?.alias || [],
    redirect: faqHy4lQwUPBQMeta?.redirect,
    component: () => import("/src/pages/admins/setting/faq.vue").then(m => m.default || m)
  },
  {
    name: help8aIrscnZ1FMeta?.name ?? "admins-setting-help",
    path: help8aIrscnZ1FMeta?.path ?? "/admins/setting/help",
    meta: help8aIrscnZ1FMeta || {},
    alias: help8aIrscnZ1FMeta?.alias || [],
    redirect: help8aIrscnZ1FMeta?.redirect,
    component: () => import("/src/pages/admins/setting/help.vue").then(m => m.default || m)
  },
  {
    name: itemWo5LQqNg0rMeta?.name ?? "admins-setting-item",
    path: itemWo5LQqNg0rMeta?.path ?? "/admins/setting/item",
    meta: itemWo5LQqNg0rMeta || {},
    alias: itemWo5LQqNg0rMeta?.alias || [],
    redirect: itemWo5LQqNg0rMeta?.redirect,
    component: () => import("/src/pages/admins/setting/item.vue").then(m => m.default || m)
  },
  {
    name: owner8kMyWRKDqOMeta?.name ?? "admins-setting-owner",
    path: owner8kMyWRKDqOMeta?.path ?? "/admins/setting/owner",
    meta: owner8kMyWRKDqOMeta || {},
    alias: owner8kMyWRKDqOMeta?.alias || [],
    redirect: owner8kMyWRKDqOMeta?.redirect,
    component: () => import("/src/pages/admins/setting/owner.vue").then(m => m.default || m)
  },
  {
    name: comment3FtwVw4UAMMeta?.name ?? "admins-setting-place-comment",
    path: comment3FtwVw4UAMMeta?.path ?? "/admins/setting/place/comment",
    meta: comment3FtwVw4UAMMeta || {},
    alias: comment3FtwVw4UAMMeta?.alias || [],
    redirect: comment3FtwVw4UAMMeta?.redirect,
    component: () => import("/src/pages/admins/setting/place/comment.vue").then(m => m.default || m)
  },
  {
    name: disablehQ3e7byWljMeta?.name ?? "admins-setting-place-disable",
    path: disablehQ3e7byWljMeta?.path ?? "/admins/setting/place/disable",
    meta: disablehQ3e7byWljMeta || {},
    alias: disablehQ3e7byWljMeta?.alias || [],
    redirect: disablehQ3e7byWljMeta?.redirect,
    component: () => import("/src/pages/admins/setting/place/disable.vue").then(m => m.default || m)
  },
  {
    name: index3ttkmkxqebMeta?.name ?? "admins-setting-place",
    path: index3ttkmkxqebMeta?.path ?? "/admins/setting/place",
    meta: index3ttkmkxqebMeta || {},
    alias: index3ttkmkxqebMeta?.alias || [],
    redirect: index3ttkmkxqebMeta?.redirect,
    component: () => import("/src/pages/admins/setting/place/index.vue").then(m => m.default || m)
  },
  {
    name: storepnVLZ1kUT1Meta?.name ?? "admins-setting-store",
    path: storepnVLZ1kUT1Meta?.path ?? "/admins/setting/store",
    meta: storepnVLZ1kUT1Meta || {},
    alias: storepnVLZ1kUT1Meta?.alias || [],
    redirect: storepnVLZ1kUT1Meta?.redirect,
    component: () => import("/src/pages/admins/setting/store.vue").then(m => m.default || m)
  },
  {
    name: typeMFzJVOQpm7Meta?.name ?? "admins-setting-type",
    path: typeMFzJVOQpm7Meta?.path ?? "/admins/setting/type",
    meta: typeMFzJVOQpm7Meta || {},
    alias: typeMFzJVOQpm7Meta?.alias || [],
    redirect: typeMFzJVOQpm7Meta?.redirect,
    component: () => import("/src/pages/admins/setting/type.vue").then(m => m.default || m)
  },
  {
    name: userU8rK9UaTuPMeta?.name ?? "admins-setting-user",
    path: userU8rK9UaTuPMeta?.path ?? "/admins/setting/user",
    meta: userU8rK9UaTuPMeta || {},
    alias: userU8rK9UaTuPMeta?.alias || [],
    redirect: userU8rK9UaTuPMeta?.redirect,
    component: () => import("/src/pages/admins/setting/user.vue").then(m => m.default || m)
  },
  {
    name: bookmarkPyFKWGbHiuMeta?.name ?? "clients-bookmark",
    path: bookmarkPyFKWGbHiuMeta?.path ?? "/clients/bookmark",
    meta: bookmarkPyFKWGbHiuMeta || {},
    alias: bookmarkPyFKWGbHiuMeta?.alias || [],
    redirect: bookmarkPyFKWGbHiuMeta?.redirect,
    component: () => import("/src/pages/clients/bookmark.vue").then(m => m.default || m)
  },
  {
    name: calendarhX3SjdkZeyMeta?.name ?? "clients-calendar",
    path: calendarhX3SjdkZeyMeta?.path ?? "/clients/calendar",
    meta: calendarhX3SjdkZeyMeta || {},
    alias: calendarhX3SjdkZeyMeta?.alias || [],
    redirect: calendarhX3SjdkZeyMeta?.redirect,
    component: () => import("/src/pages/clients/calendar.vue").then(m => m.default || m)
  },
  {
    name: chat69mCtFxI0CMeta?.name ?? "clients-chat",
    path: chat69mCtFxI0CMeta?.path ?? "/clients/chat",
    meta: chat69mCtFxI0CMeta || {},
    alias: chat69mCtFxI0CMeta?.alias || [],
    redirect: chat69mCtFxI0CMeta?.redirect,
    component: () => import("/src/pages/clients/chat.vue").then(m => m.default || m)
  },
  {
    name: dashboardt3bacwKS5OMeta?.name ?? "clients-dashboard",
    path: dashboardt3bacwKS5OMeta?.path ?? "/clients/dashboard",
    meta: dashboardt3bacwKS5OMeta || {},
    alias: dashboardt3bacwKS5OMeta?.alias || [],
    redirect: dashboardt3bacwKS5OMeta?.redirect,
    component: () => import("/src/pages/clients/dashboard.vue").then(m => m.default || m)
  },
  {
    name: entryRlKOnGTatKMeta?.name ?? "clients-entry",
    path: entryRlKOnGTatKMeta?.path ?? "/clients/entry",
    meta: entryRlKOnGTatKMeta || {},
    alias: entryRlKOnGTatKMeta?.alias || [],
    redirect: entryRlKOnGTatKMeta?.redirect,
    component: () => import("/src/pages/clients/entry.vue").then(m => m.default || m)
  },
  {
    name: _91id_93eKqeYrDmK5Meta?.name ?? "clients-facility-id",
    path: _91id_93eKqeYrDmK5Meta?.path ?? "/clients/facility/:id()",
    meta: _91id_93eKqeYrDmK5Meta || {},
    alias: _91id_93eKqeYrDmK5Meta?.alias || [],
    redirect: _91id_93eKqeYrDmK5Meta?.redirect,
    component: () => import("/src/pages/clients/facility/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93NDIUShEzBdMeta?.name ?? "clients-form-id",
    path: _91id_93NDIUShEzBdMeta?.path ?? "/clients/form/:id()",
    meta: _91id_93NDIUShEzBdMeta || {},
    alias: _91id_93NDIUShEzBdMeta?.alias || [],
    redirect: _91id_93NDIUShEzBdMeta?.redirect,
    component: () => import("/src/pages/clients/form/[id].vue").then(m => m.default || m)
  },
  {
    name: newsTPmIt7Je5TMeta?.name ?? "clients-news",
    path: newsTPmIt7Je5TMeta?.path ?? "/clients/news",
    meta: newsTPmIt7Je5TMeta || {},
    alias: newsTPmIt7Je5TMeta?.alias || [],
    redirect: newsTPmIt7Je5TMeta?.redirect,
    component: () => import("/src/pages/clients/news.vue").then(m => m.default || m)
  },
  {
    name: pickup1mymelIzWwMeta?.name ?? "clients-pickup",
    path: pickup1mymelIzWwMeta?.path ?? "/clients/pickup",
    meta: pickup1mymelIzWwMeta || {},
    alias: pickup1mymelIzWwMeta?.alias || [],
    redirect: pickup1mymelIzWwMeta?.redirect,
    component: () => import("/src/pages/clients/pickup.vue").then(m => m.default || m)
  },
  {
    name: requestExQer3fonyMeta?.name ?? "clients-request",
    path: requestExQer3fonyMeta?.path ?? "/clients/request",
    meta: requestExQer3fonyMeta || {},
    alias: requestExQer3fonyMeta?.alias || [],
    redirect: requestExQer3fonyMeta?.redirect,
    component: () => import("/src/pages/clients/request.vue").then(m => m.default || m)
  },
  {
    name: searchnJfEkANpgvMeta?.name ?? "clients-search",
    path: searchnJfEkANpgvMeta?.path ?? "/clients/search",
    meta: searchnJfEkANpgvMeta || {},
    alias: searchnJfEkANpgvMeta?.alias || [],
    redirect: searchnJfEkANpgvMeta?.redirect,
    component: () => import("/src/pages/clients/search.vue").then(m => m.default || m)
  },
  {
    name: contactjt1IjhjjXuMeta?.name ?? "contact",
    path: contactjt1IjhjjXuMeta?.path ?? "/contact",
    meta: contactjt1IjhjjXuMeta || {},
    alias: contactjt1IjhjjXuMeta?.alias || [],
    redirect: contactjt1IjhjjXuMeta?.redirect,
    component: () => import("/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RSPsdBAuegMeta?.name ?? "facility-id",
    path: _91id_93RSPsdBAuegMeta?.path ?? "/facility/:id()",
    meta: _91id_93RSPsdBAuegMeta || {},
    alias: _91id_93RSPsdBAuegMeta?.alias || [],
    redirect: _91id_93RSPsdBAuegMeta?.redirect,
    component: () => import("/src/pages/facility/[id].vue").then(m => m.default || m)
  },
  {
    name: newnu4fVe7aCQMeta?.name ?? "facility-new",
    path: newnu4fVe7aCQMeta?.path ?? "/facility/new",
    meta: newnu4fVe7aCQMeta || {},
    alias: newnu4fVe7aCQMeta?.alias || [],
    redirect: newnu4fVe7aCQMeta?.redirect,
    component: () => import("/src/pages/facility/new.vue").then(m => m.default || m)
  },
  {
    name: pickup4zcGKG7AkNMeta?.name ?? "facility-pickup",
    path: pickup4zcGKG7AkNMeta?.path ?? "/facility/pickup",
    meta: pickup4zcGKG7AkNMeta || {},
    alias: pickup4zcGKG7AkNMeta?.alias || [],
    redirect: pickup4zcGKG7AkNMeta?.redirect,
    component: () => import("/src/pages/facility/pickup.vue").then(m => m.default || m)
  },
  {
    name: rankGaRX3pKmP6Meta?.name ?? "facility-rank",
    path: rankGaRX3pKmP6Meta?.path ?? "/facility/rank",
    meta: rankGaRX3pKmP6Meta || {},
    alias: rankGaRX3pKmP6Meta?.alias || [],
    redirect: rankGaRX3pKmP6Meta?.redirect,
    component: () => import("/src/pages/facility/rank.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "forgot-password",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordhkQDFGWAYaMeta || {},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_932BpQp3ektUMeta?.name ?? "help-articles-id",
    path: _91id_932BpQp3ektUMeta?.path ?? "/help/articles/:id()",
    meta: _91id_932BpQp3ektUMeta || {},
    alias: _91id_932BpQp3ektUMeta?.alias || [],
    redirect: _91id_932BpQp3ektUMeta?.redirect,
    component: () => import("/src/pages/help/articles/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vorN6bxGqPMeta?.name ?? "help-collections-id",
    path: _91id_93vorN6bxGqPMeta?.path ?? "/help/collections/:id()",
    meta: _91id_93vorN6bxGqPMeta || {},
    alias: _91id_93vorN6bxGqPMeta?.alias || [],
    redirect: _91id_93vorN6bxGqPMeta?.redirect,
    component: () => import("/src/pages/help/collections/[id].vue").then(m => m.default || m)
  },
  {
    name: index2yHnvb2Km1Meta?.name ?? "help",
    path: index2yHnvb2Km1Meta?.path ?? "/help",
    meta: index2yHnvb2Km1Meta || {},
    alias: index2yHnvb2Km1Meta?.alias || [],
    redirect: index2yHnvb2Km1Meta?.redirect,
    component: () => import("/src/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index",
    path: indexvsa0KwJWqiMeta?.path ?? "/",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "login",
    path: loginTLBFe6DICLMeta?.path ?? "/login",
    meta: loginTLBFe6DICLMeta || {},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: memberl7IublsEQmMeta?.name ?? "member",
    path: memberl7IublsEQmMeta?.path ?? "/member",
    meta: memberl7IublsEQmMeta || {},
    alias: memberl7IublsEQmMeta?.alias || [],
    redirect: memberl7IublsEQmMeta?.redirect,
    component: () => import("/src/pages/member.vue").then(m => m.default || m)
  },
  {
    name: not_45authorizedFi7G6I00NxMeta?.name ?? "not-authorized",
    path: not_45authorizedFi7G6I00NxMeta?.path ?? "/not-authorized",
    meta: not_45authorizedFi7G6I00NxMeta || {},
    alias: not_45authorizedFi7G6I00NxMeta?.alias || [],
    redirect: not_45authorizedFi7G6I00NxMeta?.redirect,
    component: () => import("/src/pages/not-authorized.vue").then(m => m.default || m)
  },
  {
    name: callbackYfUopZFkVZMeta?.name ?? "oauth-facebook-callback",
    path: callbackYfUopZFkVZMeta?.path ?? "/oauth/facebook/callback",
    meta: callbackYfUopZFkVZMeta || {},
    alias: callbackYfUopZFkVZMeta?.alias || [],
    redirect: callbackYfUopZFkVZMeta?.redirect,
    component: () => import("/src/pages/oauth/facebook/callback.vue").then(m => m.default || m)
  },
  {
    name: callback6VxB2h7dixMeta?.name ?? "oauth-google-callback",
    path: callback6VxB2h7dixMeta?.path ?? "/oauth/google/callback",
    meta: callback6VxB2h7dixMeta || {},
    alias: callback6VxB2h7dixMeta?.alias || [],
    redirect: callback6VxB2h7dixMeta?.redirect,
    component: () => import("/src/pages/oauth/google/callback.vue").then(m => m.default || m)
  },
  {
    name: callbackpEbZOELfR1Meta?.name ?? "oauth-instagrambasic-callback",
    path: callbackpEbZOELfR1Meta?.path ?? "/oauth/instagrambasic/callback",
    meta: callbackpEbZOELfR1Meta || {},
    alias: callbackpEbZOELfR1Meta?.alias || [],
    redirect: callbackpEbZOELfR1Meta?.redirect,
    component: () => import("/src/pages/oauth/instagrambasic/callback.vue").then(m => m.default || m)
  },
  {
    name: callbackNoPteqFKraMeta?.name ?? "oauth-twitter-callback",
    path: callbackNoPteqFKraMeta?.path ?? "/oauth/twitter/callback",
    meta: callbackNoPteqFKraMeta || {},
    alias: callbackNoPteqFKraMeta?.alias || [],
    redirect: callbackNoPteqFKraMeta?.redirect,
    component: () => import("/src/pages/oauth/twitter/callback.vue").then(m => m.default || m)
  },
  {
    name: calendarFZM1aQ4TutMeta?.name ?? "owners-calendar",
    path: calendarFZM1aQ4TutMeta?.path ?? "/owners/calendar",
    meta: calendarFZM1aQ4TutMeta || {},
    alias: calendarFZM1aQ4TutMeta?.alias || [],
    redirect: calendarFZM1aQ4TutMeta?.redirect,
    component: () => import("/src/pages/owners/calendar.vue").then(m => m.default || m)
  },
  {
    name: chatAs5puJNMWkMeta?.name ?? "owners-chat",
    path: chatAs5puJNMWkMeta?.path ?? "/owners/chat",
    meta: chatAs5puJNMWkMeta || {},
    alias: chatAs5puJNMWkMeta?.alias || [],
    redirect: chatAs5puJNMWkMeta?.redirect,
    component: () => import("/src/pages/owners/chat.vue").then(m => m.default || m)
  },
  {
    name: dashboardlkzeD8FqQhMeta?.name ?? "owners-dashboard",
    path: dashboardlkzeD8FqQhMeta?.path ?? "/owners/dashboard",
    meta: dashboardlkzeD8FqQhMeta || {},
    alias: dashboardlkzeD8FqQhMeta?.alias || [],
    redirect: dashboardlkzeD8FqQhMeta?.redirect,
    component: () => import("/src/pages/owners/dashboard.vue").then(m => m.default || m)
  },
  {
    name: entryUjlmjcqKufMeta?.name ?? "owners-entry",
    path: entryUjlmjcqKufMeta?.path ?? "/owners/entry",
    meta: entryUjlmjcqKufMeta || {},
    alias: entryUjlmjcqKufMeta?.alias || [],
    redirect: entryUjlmjcqKufMeta?.redirect,
    component: () => import("/src/pages/owners/entry.vue").then(m => m.default || m)
  },
  {
    name: indexQEz8wDx0cVMeta?.name ?? "owners-facility",
    path: indexQEz8wDx0cVMeta?.path ?? "/owners/facility",
    meta: indexQEz8wDx0cVMeta || {},
    alias: indexQEz8wDx0cVMeta?.alias || [],
    redirect: indexQEz8wDx0cVMeta?.redirect,
    component: () => import("/src/pages/owners/facility/index.vue").then(m => m.default || m)
  },
  {
    name: newsKeGLLEmUmMMeta?.name ?? "owners-news",
    path: newsKeGLLEmUmMMeta?.path ?? "/owners/news",
    meta: newsKeGLLEmUmMMeta || {},
    alias: newsKeGLLEmUmMMeta?.alias || [],
    redirect: newsKeGLLEmUmMMeta?.redirect,
    component: () => import("/src/pages/owners/news.vue").then(m => m.default || m)
  },
  {
    name: pickupOtTUFGJxo1Meta?.name ?? "owners-pickup",
    path: pickupOtTUFGJxo1Meta?.path ?? "/owners/pickup",
    meta: pickupOtTUFGJxo1Meta || {},
    alias: pickupOtTUFGJxo1Meta?.alias || [],
    redirect: pickupOtTUFGJxo1Meta?.redirect,
    component: () => import("/src/pages/owners/pickup.vue").then(m => m.default || m)
  },
  {
    name: salesTQRZkqHgfWMeta?.name ?? "owners-sales",
    path: salesTQRZkqHgfWMeta?.path ?? "/owners/sales",
    meta: salesTQRZkqHgfWMeta || {},
    alias: salesTQRZkqHgfWMeta?.alias || [],
    redirect: salesTQRZkqHgfWMeta?.redirect,
    component: () => import("/src/pages/owners/sales.vue").then(m => m.default || m)
  },
  {
    name: schedulergspfGQ0xDwMeta?.name ?? "owners-scheduler",
    path: schedulergspfGQ0xDwMeta?.path ?? "/owners/scheduler",
    meta: schedulergspfGQ0xDwMeta || {},
    alias: schedulergspfGQ0xDwMeta?.alias || [],
    redirect: schedulergspfGQ0xDwMeta?.redirect,
    component: () => import("/src/pages/owners/scheduler.vue").then(m => m.default || m)
  },
  {
    name: _91tab_93a8L4j3Uzz1Meta?.name ?? "pages-account-settings-tab",
    path: _91tab_93a8L4j3Uzz1Meta?.path ?? "/pages/account-settings/:tab()",
    meta: _91tab_93a8L4j3Uzz1Meta || {},
    alias: _91tab_93a8L4j3Uzz1Meta?.alias || [],
    redirect: _91tab_93a8L4j3Uzz1Meta?.redirect,
    component: () => import("/src/pages/pages/account-settings/[tab].vue").then(m => m.default || m)
  },
  {
    name: faqhUdqgC6OBuMeta?.name ?? "pages-faq",
    path: faqhUdqgC6OBuMeta?.path ?? "/pages/faq",
    meta: faqhUdqgC6OBuMeta || {},
    alias: faqhUdqgC6OBuMeta?.alias || [],
    redirect: faqhUdqgC6OBuMeta?.redirect,
    component: () => import("/src/pages/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _91article_93BIhv5Q7gqYMeta?.name ?? "pages-help-center-category-subcategory-article",
    path: _91article_93BIhv5Q7gqYMeta?.path ?? "/pages/help-center/:category()/:subcategory()/:article()",
    meta: _91article_93BIhv5Q7gqYMeta || {},
    alias: _91article_93BIhv5Q7gqYMeta?.alias || [],
    redirect: _91article_93BIhv5Q7gqYMeta?.redirect,
    component: () => import("/src/pages/pages/help-center/[category]/[subcategory]/[article].vue").then(m => m.default || m)
  },
  {
    name: indexbrDIgPtEH5Meta?.name ?? "pages-help-center-category-subcategory",
    path: indexbrDIgPtEH5Meta?.path ?? "/pages/help-center/:category()/:subcategory()",
    meta: indexbrDIgPtEH5Meta || {},
    alias: indexbrDIgPtEH5Meta?.alias || [],
    redirect: indexbrDIgPtEH5Meta?.redirect,
    component: () => import("/src/pages/pages/help-center/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSkmVw8vtlsMeta?.name ?? "pages-help-center",
    path: indexSkmVw8vtlsMeta?.path ?? "/pages/help-center",
    meta: indexSkmVw8vtlsMeta || {},
    alias: indexSkmVw8vtlsMeta?.alias || [],
    redirect: indexSkmVw8vtlsMeta?.redirect,
    component: () => import("/src/pages/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: coming_45soonUaHZKkFWv7Meta?.name ?? "pages-misc-coming-soon",
    path: coming_45soonUaHZKkFWv7Meta?.path ?? "/pages/misc/coming-soon",
    meta: coming_45soonUaHZKkFWv7Meta || {},
    alias: coming_45soonUaHZKkFWv7Meta?.alias || [],
    redirect: coming_45soonUaHZKkFWv7Meta?.redirect,
    component: () => import("/src/pages/pages/misc/coming-soon.vue").then(m => m.default || m)
  },
  {
    name: under_45maintenanceQzWFZnfw1HMeta?.name ?? "pages-misc-under-maintenance",
    path: under_45maintenanceQzWFZnfw1HMeta?.path ?? "/pages/misc/under-maintenance",
    meta: under_45maintenanceQzWFZnfw1HMeta || {},
    alias: under_45maintenanceQzWFZnfw1HMeta?.alias || [],
    redirect: under_45maintenanceQzWFZnfw1HMeta?.redirect,
    component: () => import("/src/pages/pages/misc/under-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qw2vZTRxA1Meta?.name ?? "post-slug",
    path: _91slug_93qw2vZTRxA1Meta?.path ?? "/post/:slug()",
    meta: _91slug_93qw2vZTRxA1Meta || {},
    alias: _91slug_93qw2vZTRxA1Meta?.alias || [],
    redirect: _91slug_93qw2vZTRxA1Meta?.redirect,
    component: () => import("/src/pages/post/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91category_936RbuKwksezMeta?.name ?? "post-categories-category",
    path: _91category_936RbuKwksezMeta?.path ?? "/post/categories/:category()",
    meta: _91category_936RbuKwksezMeta || {},
    alias: _91category_936RbuKwksezMeta?.alias || [],
    redirect: _91category_936RbuKwksezMeta?.redirect,
    component: () => import("/src/pages/post/categories/[category].vue").then(m => m.default || m)
  },
  {
    name: indexP5DXecaF7bMeta?.name ?? "post",
    path: indexP5DXecaF7bMeta?.path ?? "/post",
    meta: indexP5DXecaF7bMeta || {},
    alias: indexP5DXecaF7bMeta?.alias || [],
    redirect: indexP5DXecaF7bMeta?.redirect,
    component: () => import("/src/pages/post/index.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "register",
    path: register9mc1FDQpDSMeta?.path ?? "/register",
    meta: register9mc1FDQpDSMeta || {},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "reset-password",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/reset-password",
    meta: reset_45passwordwGIRYTe8GpMeta || {},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: searchtAmKYCO6YgMeta?.name ?? "search",
    path: searchtAmKYCO6YgMeta?.path ?? "/search",
    meta: searchtAmKYCO6YgMeta || {},
    alias: searchtAmKYCO6YgMeta?.alias || [],
    redirect: searchtAmKYCO6YgMeta?.redirect,
    component: () => import("/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: verificationD2Q3SEMGAfMeta?.name ?? "verification",
    path: verificationD2Q3SEMGAfMeta?.path ?? "/verification",
    meta: verificationD2Q3SEMGAfMeta || {},
    alias: verificationD2Q3SEMGAfMeta?.alias || [],
    redirect: verificationD2Q3SEMGAfMeta?.redirect,
    component: () => import("/src/pages/verification.vue").then(m => m.default || m)
  }
]