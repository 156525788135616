import { stringify } from 'qs'

export const $api = $fetch.create({

  // Request interceptor
  async onRequest({ options }) {
    // Set baseUrl for all API calls
    options.baseURL = `${useRuntimeConfig().public.apiBaseUrl}/api`
    if (options.params) {
      const convertedOptions = new URLSearchParams(stringify(options.params))

      options.params = Object.fromEntries(convertedOptions)
    }

    const accessToken = useCookie('accessToken').value
    if (accessToken) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      }
    }
  },
})
