import { defineStore } from 'pinia'

interface State {
  isActive: boolean
  title: string | null
  text: string | null
  color: 'success' | 'error' | 'deep-purple-accent-4'
}

export const useSnackbarStore = defineStore('snackbar', {
  state: (): State => ({
    isActive: false,
    title: null,
    text: null,
    color: 'success',
  }),
  actions: {
    unsetSnackbar() {
      this.$reset()
    },
    setActive() {
      this.isActive = true
    },
    setNotification(title: State['title'], text: State['text']) {
      this.isActive = true
      this.text = text
      this.title = title
      this.color = 'deep-purple-accent-4'
    },
    setToast(text: State['text']) {
      this.isActive = true
      this.text = text
    },
    setSuccessToast(text: State['text']) {
      this.setToast(text)
      this.color = 'success'
    },
    setErrorToast(text: State['text']) {
      this.setToast(text)
      this.color = 'error'
    },
  },
})
